import React from 'react';
import Stuff from '../components/Layout';
import { graphql } from 'gatsby';

import '../css/index.css'

export default function StuffPage(props) {
    const { data } = props;
    const { markdownRemark: post } = data;
    return (
      <Stuff {...props}>
        <div>
	    <article className="blog-post">
            <div className="blog-post-content">
                <h1 className="title">
                    {post.frontmatter.title}
                </h1>
            	<div 
                    className="blog-post-content"
                    dangerouslySetInnerHTML={{ __html: post.html }}></div>
                </div>
	    </article>
        </div>
      </Stuff>
  )
}
export const pageQuery = graphql`
    query StuffQuery($path: String!) {
        markdownRemark(
            frontmatter: {
                path: {
                    eq: $path
                }
                title: { 
                    eq: "Stuff" 
                } 
            }
        ) {
            html
            frontmatter {
                title
            }
        }
    }
`;
